import { Box, Button, Heading, Stack, Text } from "@chakra-ui/react";
import { Link as GatsbyLink } from "gatsby";
import React from "react";
import { Head } from "../../components/Head";
import Layout from "../../components/Layout";
import Section from "../../components/Section";
import { SharedHero } from "../../components/shared/SharedHero";
import { AREA_CONVENZIONATA, CONTI_DEALER } from "../../constants/config";

const buttonProps = {
  borderRadius: 0,
  bg: "transparent",
  fontWeight: "normal",
  fontSize: 18,
  color: "#000",
  py: 18,
  px: "1rem",
  height: "auto",
};

const AreaDealerPage: React.FC = () => {
  return (
    <Layout>
      <Head title="Area Dealer" />

      <SharedHero />

      <Section bg={"utils.white"}>
        <Box>
          <Stack spacing={4} direction="column">
            <Heading
              as={"h1"}
              textAlign={"center"}
              fontSize={["24px", "32px"]}
              fontWeight={"400"}
              fontFamily={"body"}
              color={"accent.01"}
            >
              Accedi all'area riservata ai Dealer
            </Heading>
            <Text align={"center"}>
              Qui trovi servizi e funzionalità per gestire in autonomia le
              pratiche di finanziamento. Se sei titolare di un conto corrente o
              di un conto di servizio puoi fare il login nell'area riservata.
            </Text>
            <Button
              {...buttonProps}
              {...{
                borderWidth: 2,
                borderStyle: "solid",
                borderColor: "accent.01",
                color: "accent.01",
              }}
              as={GatsbyLink}
              target={"_blank"}
              to={AREA_CONVENZIONATA}
            >
              Area convenzionati
            </Button>
            <Button
              {...buttonProps}
              {...{
                borderWidth: 2,
                borderStyle: "solid",
                borderColor: "accent.01",
                color: "accent.01",
              }}
              as={GatsbyLink}
              target={"_blank"}
              to={CONTI_DEALER}
            >
              Conti dealer
            </Button>
          </Stack>
        </Box>
      </Section>
    </Layout>
  );
};

export default AreaDealerPage;
